import React from "react";
import "./Dashboard.css";
import { BiBriefcase } from "react-icons/bi";
import { BsBriefcaseFill, BsArrowRight } from "react-icons/bs";
import { FaUserAlt } from "react-icons/fa";
import BoxAnalysis from "./dashboardComponents/BoxAnalysis";
import { Link, useNavigate } from "react-router-dom";
import { FaCaretRight } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import useIsMobile from "../../hook/useIsMobile";
import {
  useGetApplicationCountQuery,
  useGetDashJobApplicationQuery,
  useGetJobsAlertQuery,
} from "../../store/query/dashboard";
import moment from "moment";
import { FaArrowRightLong } from "react-icons/fa6";
import CustomButton from "../../components/customButton/CustomButton";
import { config } from "../../config";
import { _figure_format } from "../../utils";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { useTypedSelector } from "../../hook/useTypedSelector";

const Dashboard = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { user } = useTypedSelector((store) => store.appUser);
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };

  const getStatus = (status) => {
    if (status === "1") {
      return "pending";
    } else if (status === "2") {
      return "successful";
    } else if (status === "6") {
      return "saved";
    }
  };

  const { data: JobAlerts } = useGetJobsAlertQuery();

  const { data: jobsApp } = useGetDashJobApplicationQuery();
  const { data: count } = useGetApplicationCountQuery();
  console.log(user.account_type === "organisation");

  return (
    <div className="dashboard-container">
      <div className="dashboard-right">
        {/* dashboard main content */}
        <div className="dashboard-right-bottom">
          {/* info box */}
          <div className="info-box">
            <BoxAnalysis
              num={_figure_format(Number(count?.allApplicationCount) ?? 0)}
              title={
                user.account_type === "organisation"
                  ? "Application Received"
                  : "Application Sent"
              }
              bg={"#FFF2E5"}
              icon={
                <BiBriefcase style={{ color: "#F5A656", fontSize: "25px" }} />
              }
            />
            <BoxAnalysis
              num={"04"}
              title="Completed Jobs"
              bg={"#90ECDE33"}
              icon={
                <BsBriefcaseFill
                  style={{ color: "#00B297", fontSize: "25px" }}
                />
              }
            />
            <BoxAnalysis
              num={"2K"}
              title={
                user.account_type === "organisation"
                  ? "Total Payout"
                  : "Total Earning"
              }
              bg={"#FFE5E8"}
              icon={
                <div
                  style={{
                    padding: "8px",
                    backgroundColor: "#D6FCFF",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <RiMoneyDollarCircleFill
                    style={{ color: "#5ECFD6", fontSize: "18px" }}
                  />
                </div>
              }
            />
          </div>

          <div className="exploreMore-container">
            <div className="exploreMore-top">
              <h3>
                Seach for available <br /> locum jobs
              </h3>
              <div className="home-box-one-button">
                <button>
                  Enter Division <BsArrowRight />
                </button>
              </div>
            </div>

            <div className="exploreMore-bottom">
              <h4>
                {user.account_type === "organisation"
                  ? "Open Jobs"
                  : "Job Alerts"}
              </h4>
              {JobAlerts?.data?.allJob.length && (
                <Slider {...settings} className="exploreMore-jobs">
                  {JobAlerts?.data?.allJob?.map((job) => {
                    const logo =
                      job.logo && `${config.getPublicImages}/${job.logo}`;
                    return (
                      <div key={job.id} className="exploreMore-Jobs-box">
                        <div className="exploreMore-title-box">
                          <div className="title">
                            <h4>{job.title}</h4>
                            <span>Rate:N{job.hourly_rate}/hr</span>
                          </div>
                          <div className="image">
                            <img src={logo} alt="img" />
                          </div>
                        </div>
                        <div className="desc">
                          <p>
                            {job.description.length > 150
                              ? job.description.slice(0, 150) + "..."
                              : job.description}
                          </p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="findJobs-button">
                            <CustomButton
                              variant="outline"
                              title="Details"
                              customStyles={{
                                width: "70px",
                                height: "25px",
                                marginTop: "0",
                                fontSize: "14px",
                                borderRadius: "5px",
                                borderWidth: "0px",
                                paddingLeft: "0px",
                              }}
                              onClick={() => {
                                navigate(`/joblistings/${job.id}`);
                              }}
                            />
                          </div>
                          <div className="applicants">
                            <h5>
                              {job.applicants <= 1
                                ? `${job.applicants} Applicant`
                                : `${job.applicants} Applicants`}
                            </h5>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              )}
            </div>
          </div>
          {/* explore more jobs */}

          {/* applications info*/}
          <div className="applications-info-container">
            <h4>
              {user.account_type === "organisation"
                ? "Ongoing Jobs"
                : "My Applications"}
            </h4>

            <div className="desktop-view">
              {/* head */}
              <div className="applications-info-heading">
                <span className="head head-1">Company Name</span>
                <span style={{ width: "16%" }} className="head">
                  Status
                </span>
                <span style={{ width: "16%" }} className="head">
                  Date Applied
                </span>
                <span style={{ width: "13%" }} className="head">
                  Action
                </span>
              </div>

              {jobsApp?.data?.allApplication?.map((app) => {
                const logo =
                  app.logo && `${config.getPublicImages}/${app.logo}`;
                return (
                  <div key={app.date_applied}>
                    <div className="applications-info-content">
                      <div className="applications-info-content-box">
                        <span className="content-1">
                          <span className="img">
                            <img src={logo} alt="hos-img" />
                          </span>
                          <span className="title">
                            <h6>{app.company_name}</h6>
                          </span>
                        </span>
                        <div style={{ width: "16%" }}>
                          <span className={`status ${getStatus(app.status)}`}>
                            {getStatus(app.status)}
                          </span>
                        </div>
                        <span style={{ width: "16%" }} className="date">
                          {moment(app.date_applied).format("LL")}
                        </span>
                        <Link
                          style={{ width: "13%" }}
                          className="date"
                          to={`/activity/${app.status}`}
                        >
                          <FaArrowRightLong
                            style={{ color: "#20a4d3", fontSize: 20 }}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="mobile-application-jobs">
              <div className="all-jobs-app">
                {jobsApp?.data?.allApplication?.map((app) => {
                  return (
                    <div key={app.date_applied} className="job-single">
                      <div className="flex">
                        <span className="label">Company Name</span>
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                          }}
                        >
                          <span className="content-1">
                            <span className="title">
                              <h6>{app.company_name}</h6>
                            </span>
                          </span>
                        </div>
                      </div>

                      <div className="flex">
                        <span className="label">Status</span>
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                          }}
                        >
                          <span className="status">
                            {" "}
                            {getStatus(app.status)}
                          </span>
                        </div>
                      </div>

                      <div className="flex">
                        <span className="label">Date Applied</span>
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                          }}
                        >
                          <span className="date">
                            {moment(app.date_applied).format("LL")}
                          </span>
                        </div>
                      </div>

                      <div className="flex">
                        <span className="label">Action</span>
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Link className="date" to={`/activity/${app.status}`}>
                            <FaCaretRight
                              style={{ color: "blue", fontSize: 20 }}
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
