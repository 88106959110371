import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./PostJob.css";
import ProfileEditInput from "../../../components/profileEditInput/ProfileEditInput";
import CustomPhoneInput from "../../../components/customPhoneInput/CustomPhoneInput";
import useCountries from "../../../hook/useCountries";
import toLower from "lodash/fp/toLower";
import { errorProps, isEmpty, neutralProps } from "../../../utils";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useTypedSelector } from "../../../hook/useTypedSelector";
import usePostJob from "../../../hook/usePostJob";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CustomButton from "../../../components/customButton/CustomButton";
import CustomAutoComplete from "../../../components/customAutoComplete/CustomAutoComplete";
import DashboardModal from "../../../components/dashboardModal/DashboardModal";
import usePublicDivision from "../../../hook/usePublicDivision";
import { useAuthQuery } from "../../../store/query/auth";
import { useGetSingleJobQuery } from "../../../store/query/jobs";
import useIsMobile from "../../../hook/useIsMobile";
import FillTimeSheet from "./components/fillTimeSheet/FillTimeSheet";
import ResnReq from "./components/resnReq/ResnReq";
import UpdateTimeSheet from "./components/fillTimeSheet/UpdateTimeSheet";

const PostJob = () => {
  const { user, token } = useTypedSelector((store) => store.appUser);
  const [duty, setDuty] = useState("");
  const [doc, setDoc] = useState("");
  const countries = useCountries();
  const { id } = useParams();
  const { data: job } = useGetSingleJobQuery({ id });
  const isMobile = useIsMobile();
  const [isOpen, setIsOpen] = useState("");

  const { data: auth } = useAuthQuery({
    token: token,
    acc_type: user?.account_type,
  });

  useEffect(() => {
    if (auth?.data && auth?.data.status !== "2") {
      toast(
        <span className="toast-msg">
          {auth?.data.status_message.length
            ? auth?.data.status_message
            : "Your Account is Under Assessment, Please be patient !"}
        </span>,
        neutralProps
      );
    }
  }, [auth]);

  const {
    values,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
    dirty,
    canSubmit,
    loading,
    states,
    cities,
    state,
    city,
  } = usePostJob({ job: job?.data, id, user });
  const { divisonList } = usePublicDivision();

  const countrySelectedOption = useMemo(() => {
    return countries.find((i) => toLower(i.value) === toLower("NG"));
  }, [countries]);

  const findSingleDivision =
    useMemo(() => {
      return divisonList?.find(
        (i) =>
          i.label === user?.division?.name ||
          values?.divisionObj?.name ||
          job?.data?.division?.name
      );
    }, [
      divisonList,
      user?.division?.name,
      values?.divisionObj?.name,
      job?.data?.division?.name,
    ]) || null;

  useEffect(() => {
    setFieldValue("divisionObj", findSingleDivision);
  }, [findSingleDivision, setFieldValue]);

  const onChangeSelect = useCallback(
    (option) => {
      handleChange({
        target: {
          name: "state",
          value: option,
        },
      });
      handleChange({
        target: {
          name: "city",
          value: null,
        },
      });
    },
    [handleChange]
  );

  const onChangeSelectCity = useCallback(
    (option) => {
      handleChange({
        target: {
          name: "city",
          value: option,
        },
      });
    },
    [handleChange]
  );

  const handAddResponsibility = () => {
    if (!isEmpty(duty)) {
      const newResponsiblities = [...values.responsibilities, duty];
      setFieldValue("responsibilities", newResponsiblities);
      setDuty("");
    }
  };

  const handleDelRes = (id) => {
    const updateRes = values.responsibilities.filter((_, i) => i !== id);
    setFieldValue("responsibilities", updateRes);
  };

  const handleDelReq = (id) => {
    const updatedReq = values.requirements.filter((_, i) => i !== id);
    setFieldValue("requirements", updatedReq);
  };

  const handAddRequirement = () => {
    if (!isEmpty(doc)) {
      const newRequirements = [...values.requirements, doc];
      setFieldValue("requirements", newRequirements);
      setDoc("");
    }
  };

  return (
    <div className="postjob-container">
      <h4>Post a Job</h4>

      <div className="postjob-content">
        <div className="inner-box">
          <div className="first-box">
            <h5>About Company</h5>
            <p>Kindly provide your company's name and other details.</p>
          </div>
          <div className="second-box">
            <div className="inner-input-1">
              <div className="auto-complete-con">
                <ProfileEditInput
                  label="Company Name"
                  placeholder="Please enter your company name"
                  width={"100%"}
                  widthCon={"100%"}
                  type="text"
                  value={values.company_name}
                  onChange={handleChange("company_name")}
                />
              </div>
              <div className="auto-complete-con">
                <ProfileEditInput
                  label="Contact email address"
                  placeholder="Enter your email address"
                  width={"100%"}
                  widthCon={"100%"}
                  type="email"
                  value={values.contact_email}
                  onChange={handleChange("contact_email")}
                />
              </div>
            </div>
            <div className="inner-input-1">
              <div className="auto-complete-con">
                <CustomPhoneInput
                  label="Phone Number"
                  width={"100%"}
                  widthCon={"100%"}
                  value={values.phone}
                  onChange={handleChange("phone")}
                />
              </div>

              <div className="auto-complete-con">
                <CustomAutoComplete
                  isClearable={false}
                  label="Nationality"
                  placeholder={"select_country"}
                  otherValue={values.nationality}
                  options={countries}
                  initOption={countrySelectedOption}
                  value={values.nationality}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="inner-box">
          <div className="first-box">
            <h5>About Job</h5>
            <p>
              Just a little secret; jobs with better offer get more engagement.
              Don't forget to make your offer enticing 😃{" "}
            </p>
          </div>
          <div className="second-box">
            <div className="inner-input-1">
              <div className="auto-complete-con">
                <ProfileEditInput
                  label="Job Title"
                  placeholder={"Select job title"}
                  width={"100%"}
                  widthCon={"100%"}
                  value={values.title}
                  onChange={handleChange("title")}
                />
              </div>

              <div className="auto-complete-con">
                <CustomAutoComplete
                  isClearable={false}
                  label="Division"
                  placeholder="Select division"
                  otherValue={values.divisionObj?.value}
                  options={
                    user?.account_type === "organisation" ? divisonList : []
                  }
                  initOption={values.divisionObj}
                  onChange={(option) => {
                    handleChange({
                      target: {
                        name: "divisionObj",
                        value: option,
                      },
                    });
                  }}
                />
              </div>
            </div>
            <div className="inner-desc">
              <label className="desc-text">Description</label>
              <textarea
                cols="60"
                value={values.description}
                onChange={handleChange("description")}
                placeholder="Write down about Company / Job"
              />
            </div>
            <div className="inner-input-1">
              <div className="auto-complete-con">
                <CustomAutoComplete
                  isClearable={false}
                  label="State"
                  placeholder={"select state"}
                  options={states}
                  initOption={state}
                  onChange={onChangeSelect}
                />
              </div>
              <div className="auto-complete-con">
                <CustomAutoComplete
                  isClearable={false}
                  label="City"
                  placeholder={"select city"}
                  options={cities}
                  //otherValue={values.city}
                  initOption={city}
                  onChange={onChangeSelectCity}
                />
              </div>
            </div>

            <div className="inner-input-1">
              <div className="auto-complete-con">
                <ProfileEditInput
                  label="Address"
                  placeholder={"Write Address"}
                  width={"100%"}
                  widthCon={"100%"}
                  value={values.address}
                  onChange={handleChange("address")}
                />
              </div>
              <div className="auto-complete-con">
                <ProfileEditInput
                  label="Hourly Rate"
                  placeholder="N0.00"
                  width={"100%"}
                  widthCon={"100%"}
                  type="number"
                  value={values.hourly_rate}
                  onChange={handleChange("hourly_rate")}
                />
              </div>
            </div>
          </div>
        </div>

        {!id ? (
          <div className="inner-box">
            <div className="first-box">
              <h5>Responsibilities</h5>
              <p>
                Outline clear responsibilities to get the best off your
                placeholder.{" "}
              </p>
            </div>

            <div className="second-box">
              <label>Describe the responsibilities</label>
              <textarea
                placeholder="Type responsibility"
                rows={3}
                value={duty}
                onChange={(e) => setDuty(e.target.value)}
              />
              <button onClick={handAddResponsibility}>ADD</button>

              <div className="min-box">
                {values?.responsibilities?.length === 0 ? (
                  <div className="no-res">
                    <p>Add Responsibilities</p>
                  </div>
                ) : (
                  values?.responsibilities?.map((res, i) => (
                    <div key={i} className="res-item">
                      <div className="res-item-content">
                        <div className="bg-dot">
                          <div className="dot-num" />
                        </div>
                        <div className="res-item-words">
                          <p>{res}</p>
                        </div>
                      </div>

                      <div className="res-bg-icon">
                        <RiDeleteBin6Line
                          className="icon-btn-del"
                          onClick={() => handleDelRes(i)}
                        />
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="btn">
            <CustomButton
              onClick={() => {
                setIsOpen("RESPONSIBILITY");
              }}
              title="Responsibilities"
              disable={loading}
              customStyles={{
                width: "100%",
                borderRadius: "5px",
                backgroundColor: "#245293",
                color: "#fff",
              }}
            />
          </div>
        )}

        {!id ? (
          <div className="inner-box">
            <div className="first-box">
              <h5>Requirements</h5>
              <p>State each Requirements for applicants. </p>
            </div>

            <div className="second-box">
              <label>Requirements</label>
              <textarea
                placeholder="Type requirement"
                value={doc}
                onChange={(e) => setDoc(e.target.value)}
              />
              <button onClick={handAddRequirement}>ADD</button>

              <div className="min-box">
                {values?.requirements?.length === 0 ? (
                  <div className="no-res">
                    <p>Add Requirements</p>
                  </div>
                ) : (
                  values?.requirements?.map((req, i) => (
                    <div key={i} className="res-item">
                      <div className="res-item-content">
                        <div className="bg-dot">
                          <div className="dot-num" />
                        </div>
                        <div className="res-item-words">
                          <p>{req}</p>
                        </div>
                      </div>

                      <div className="res-bg-icon">
                        <RiDeleteBin6Line
                          className="icon-btn-del"
                          onClick={() => handleDelReq(i)}
                        />
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="btn">
            <CustomButton
              onClick={() => {
                setIsOpen("REQUIREMENT");
              }}
              title="Requirements"
              disable={loading}
              customStyles={{
                width: "100%",
                borderRadius: "5px",
                backgroundColor: "#245293",
                color: "#fff",
              }}
            />
          </div>
        )}

        <div className="btn-bg">
          <div className="btn">
            <CustomButton
              onClick={() => {
                if (values.hourly_rate.length === 0) {
                  toast.error("please add hourly rate", errorProps);
                } else if (!id) {
                  setIsOpen("TIMESHEET1");
                } else {
                  setIsOpen("TIMESHEET2");
                }
              }}
              title="Fill Timesheet"
              disable={loading || !values.hourly_rate}
              customStyles={{
                width: "100%",
                borderRadius: "5px",
                backgroundColor: "#245293",
                color: "#fff",
              }}
            />
          </div>
          <div className="btn">
            <CustomButton
              title="Reset"
              type="btn"
              disable={!dirty || loading}
              onClick={handleReset}
              background={"red"}
              border={"red"}
              customStyles={{
                width: "100%",
                borderRadius: "5px",
                color: "#fff",
              }}
            />
          </div>

          <div className="btn">
            <CustomButton
              onClick={() => {
                if (canSubmit) handleSubmit();
              }}
              title={id ? "Update" : "Publish"}
              type="submit"
              loading={loading}
              disable={!canSubmit || loading}
              customStyles={{
                width: "100%",
                borderRadius: "5px",
                backgroundColor: "#245293",
                color: "#fff",
              }}
            />
          </div>
        </div>
      </div>

      <DashboardModal
        customStyle={{
          width: isMobile ? "95%" : "80%",
        }}
        isOpen={isOpen.length > 0}
      >
        {isOpen === "TIMESHEET1" && (
          <FillTimeSheet
            values={values}
            setFieldValue={setFieldValue}
            close={() => setIsOpen("")}
          />
        )}

        {isOpen === "TIMESHEET2" && (
          <UpdateTimeSheet
            rate={values.hourly_rate}
            acc_type={user?.account_type}
            job_id={id}
            close={() => setIsOpen("")}
          />
        )}

        {isOpen === "RESPONSIBILITY" && (
          <ResnReq
            title="responsibility"
            acc_type={user?.account_type}
            id={id}
            close={() => setIsOpen("")}
          />
        )}

        {isOpen === "REQUIREMENT" && (
          <ResnReq
            title="requirement"
            acc_type={user?.account_type}
            id={id}
            close={() => setIsOpen("")}
          />
        )}
      </DashboardModal>
    </div>
  );
};

export default PostJob;
