import React from "react";
import "./ProfileEditDate.css";

const ProfileEditDate = (props) => {
  const {
    label,
    icon,
    placeholder,
    widthCon,
    type = "text",
    value,
    onChange,
    customStyles,
    readOnly,
    ...rest
  } = props;
  return (
    <div
      style={{ width: widthCon, ...customStyles?.bg }}
      className="profile-edit-date-cv-body"
    >
      <label
        style={{
          color: "#777",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "24px",
          ...customStyles?.label,
        }}
      >
        {label}
      </label>

      <div
        style={{
          width: widthCon,
          ...customStyles?.input,
        }}
        className="input-edit-container"
      >
        {icon}
        <input
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          type={type}
          style={
            icon
              ? {
                  borderLeft: "1px solid #efefef",
                  marginLeft: "10px",
                  paddingLeft: "15px",
                }
              : { paddingLeft: "6px" }
          }
          readOnly={readOnly}
          {...rest}
        />
      </div>
    </div>
  );
};

export default ProfileEditDate;
