import React, { useCallback, useState } from "react";
import { _currency_format } from "../../../../../utils";
import { RiDeleteBinLine } from "react-icons/ri";
import CustomButton from "../../../../../components/customButton/CustomButton";
import DateFrameInput from "../../../../../components/activity/components/dateFrameInput/DateFrameInput";
import TimeFrameInput from "../../../../../components/activity/components/timeFrameInput/TimeFrameInput";
import "./FillTimeSheet.css";
import {
  useAddTimeSheetMutation,
  useDeleteTimeSheetMutation,
  useGetTimeSheetQuery,
  useUpdateTimeSheetMutation,
} from "../../../../../store/query/jobs";
import { Bars } from "react-loader-spinner";
import { CiEdit } from "react-icons/ci";
import { toast } from "react-toastify";

const UpdateTimeSheet = ({ job_id, acc_type, rate, close }) => {
  const [sheetDetails, setSheetDetails] = useState({
    id: "",
    date_from: "",
    date_to: "",
    time_from: "",
    time_to: "",
    hour: "",
    charge: "",
  });
  //console.log(sheetDetails);

  const [addTimeSheet, { isLoading: isAddingTimeSheet }] =
    useAddTimeSheetMutation();
  const [updateTimeSheet, { isLoading: isUpdatingTimeSheet }] =
    useUpdateTimeSheetMutation();
  const [deleteTimeSheet, { isLoading: isDeletingTimeSheet }] =
    useDeleteTimeSheetMutation();

  const {
    data: timesheet,
    isLoading: isGettingTimeSheet,
    refetch,
  } = useGetTimeSheetQuery({
    acc_type,
    job_id,
  });
  //console.log(timesheet?.data);
  const loading =
    isAddingTimeSheet ||
    isGettingTimeSheet ||
    isUpdatingTimeSheet ||
    isDeletingTimeSheet;

  const [errors, setErrors] = useState({});

  const check = () => {
    const today = new Date();
    const dateFrom = new Date(sheetDetails.date_from);
    const dateTo = new Date(sheetDetails.date_to);
    let [hours1] = sheetDetails.time_from.split(":").map(Number);
    let [hours2] = sheetDetails.time_to.split(":").map(Number);

    // let now = new Date();
    // let currentHour = now.getHours();

    const newErrors = {};
    if (!sheetDetails.date_from.length) {
      newErrors.date_from = "Required";
    } else if (!sheetDetails.date_to.length) {
      newErrors.date_to = "Required";
    } else if (
      today.toISOString().split("T")[0] > dateFrom.toISOString().split("T")[0]
    ) {
      newErrors.date_from = "Today or future date";
    } else if (dateFrom > dateTo) {
      newErrors.date_to = "End date must be greater";
    } else if (hours1 > hours2) {
      newErrors.time_to = "End must be greater";
    } else if (!sheetDetails.time_from.length) {
      newErrors.time_from = "Required";
    } else if (!sheetDetails.time_to.length) {
      newErrors.time_to = "Required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const getHoursnCharge = () => {
    const dateFrom = new Date(sheetDetails.date_from);
    const dateTo = new Date(sheetDetails.date_to);

    const timeFrom = new Date(`1970-01-01T${sheetDetails.time_from}:00Z`);
    const timeTo = new Date(`1970-01-01T${sheetDetails.time_to}:00Z`);

    let diff_in_time = dateTo.getTime() - dateFrom.getTime();
    let diff_in_days = Math.round(diff_in_time / (1000 * 3600 * 24));

    let totalDays = diff_in_days + 1;
    const diffInMilliseconds = timeTo - timeFrom;

    const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
    const hours = totalDays * diffInHours;
    const charge = hours * Number(rate);

    //   setSheetDetails((prev) => ({
    //     ...prev,
    //     hour: hours,
    //     charge: charge,
    //   }));

    return { hours, charge };
  };

  const totalHours = useCallback(() => {
    return timesheet?.data?.allJobTimesheet?.reduce(
      (accumulator, currentItem) => {
        return accumulator + Number(currentItem.hour);
      },
      0
    );
  }, [timesheet?.data?.allJobTimesheet]);

  const totalCharge = useCallback(() => {
    return timesheet?.data?.allJobTimesheet?.reduce(
      (accumulator, currentItem) => {
        return accumulator + Number(currentItem.charge);
      },
      0
    );
  }, [timesheet?.data?.allJobTimesheet]);

  const handleAdd = async () => {
    if (check()) {
      if (getHoursnCharge()) {
        const { hours, charge } = getHoursnCharge();
        const payload = {
          acc_type,
          body: {
            start_date: sheetDetails.date_from,
            end_date: sheetDetails.date_to,
            start_time: sheetDetails.time_from,
            end_time: sheetDetails.time_to,
            hour: hours,
            charge: charge,
            hourly_rate: rate,
            job_id,
          },
        };
        const res = await addTimeSheet(payload);
        //console.log(res, payload);
        if (res.data.status) {
          toast.success(res.data.message);
          refetch();
          setSheetDetails({
            id: "",
            date_from: "",
            date_to: "",
            time_from: "",
            time_to: "",
            hour: "",
            charge: "",
          });
        } else {
          toast.error("Failed to add!");
        }
      }
    }
  };

  const handleUpdate = async () => {
    if (check()) {
      if (getHoursnCharge()) {
        const { hours, charge } = getHoursnCharge();
        const payload = {
          acc_type,
          id: sheetDetails.id,
          body: {
            start_date: sheetDetails.date_from,
            end_date: sheetDetails.date_to,
            start_time: sheetDetails.time_from,
            end_time: sheetDetails.time_to,
            hour: hours,
            charge: charge,
            hourly_rate: rate,
            job_id,
            _method: "PATCH",
          },
        };
        const res = await updateTimeSheet(payload);
        //console.log(res, payload);
        if (res.data.status) {
          toast.success(res.data.message);
          refetch();
          setSheetDetails({
            id: "",
            date_from: "",
            date_to: "",
            time_from: "",
            time_to: "",
            hour: "",
            charge: "",
          });
        } else {
          toast.error("Failed to add!");
        }
      }
    }
  };

  const handleDelTimeSheet = async (id) => {
    const payload = {
      acc_type,
      id,
    };

    const res = await deleteTimeSheet(payload);
    //console.log(res, payload);
    if (res.data.status) {
      toast.success(res.data.message);
      refetch();
    } else {
      toast.error("Failed to delete!");
    }
  };

  return (
    <div className="dashboard-modal">
      <h3>Updated Locum Timesheet</h3>

      <div className="box-timesheet">
        <div className="box-timesheet-input-cover">
          <div className="fill-bg">
            <DateFrameInput
              label="Start and End Date"
              values={sheetDetails}
              setValues={setSheetDetails}
              error={errors}
            />
          </div>
          <div className="fill-bg">
            <TimeFrameInput
              label="Start and End Time"
              values={sheetDetails}
              setValues={setSheetDetails}
              error={errors}
            />
          </div>

          <div className="add-rows-btn">
            <CustomButton
              variant="outline"
              onClick={() => (sheetDetails.id ? handleUpdate() : handleAdd())}
              loading={loading}
              disable={loading}
              title={sheetDetails.id ? "EDIT" : "ADD"}
              customStyles={{
                borderRadius: "0",
                height: "48px",
              }}
            />
          </div>
        </div>

        {loading ? (
          <div className="loading-timesheet">
            <Bars
              height="50"
              width="50"
              color=" #245293"
              ariaLabel="bars-loading"
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          timesheet?.data?.allJobTimesheet?.length > 0 && (
            <div className="candidates-result">
              <div className="candidates-result-headings">
                <span>Start Date</span>
                <span>End Date</span>
                <span>Start Time</span>
                <span>End Time</span>
                <span>Hours</span>
                <span>Charges</span>
                <span>Action</span>
              </div>
              <div className="candidates-result-box">
                {timesheet?.data?.allJobTimesheet?.map((item) => (
                  <div key={item.id} className="single-candidate">
                    <div className="single">
                      <span className="mobile-time">Start Date:</span>
                      <span>{item.start_date}</span>
                    </div>
                    <div className="single">
                      <span className="mobile-time">End Date:</span>
                      <span>{item.end_date}</span>
                    </div>
                    <div className="single">
                      <span className="mobile-time">Start Time:</span>
                      <span>{item.start_time}</span>
                    </div>
                    <div className="single">
                      <span className="mobile-time">End Time:</span>
                      <span>{item.end_time}</span>
                    </div>
                    <div className="single">
                      <span className="mobile-time">Hours:</span>
                      <span>{item.hour}</span>
                    </div>
                    <div className="single">
                      <span className="mobile-time">Charges:</span>
                      <span>
                        {_currency_format(Number(item.charge), "NGN")}
                      </span>
                    </div>
                    <div className="single">
                      <span className="mobile-time">Action:</span>
                      <div className="icons-btn">
                        <CiEdit
                          style={{ cursor: "pointer" }}
                          size="20"
                          onClick={() => {
                            setSheetDetails({
                              id: item.id,
                              date_from: item.start_date,
                              date_to: item.end_date,
                              time_from: item.start_time,
                              time_to: item.end_time,
                              hour: item.hour,
                              charge: item.charge,
                            });
                          }}
                          color="#1877F2"
                        />
                        <RiDeleteBinLine
                          style={{ cursor: "pointer" }}
                          color="red"
                          size="20"
                          onClick={() => handleDelTimeSheet(item.id)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  marginTop: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      fontSize: "15px",
                      fontWeight: 700,
                      color: "#465174",
                    }}
                  >
                    Total Hours:
                  </span>
                  <span
                    style={{
                      fontSize: "15px",
                      fontWeight: 800,
                    }}
                  >
                    {totalHours()}
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      fontSize: "15px",
                      fontWeight: 700,
                      color: "#465174",
                    }}
                  >
                    Total Charge:
                  </span>
                  <span
                    style={{
                      fontSize: "15px",
                      fontWeight: 800,
                    }}
                  >
                    {_currency_format(Number(totalCharge()), "NGN")}
                  </span>
                </div>
              </div>
            </div>
          )
        )}
      </div>

      <div className="btn-bg">
        <div className="btn">
          <CustomButton
            title="Close"
            type="btn"
            onClick={close}
            background={"red"}
            border={"red"}
            customStyles={{
              width: "100%",
              borderRadius: "5px",
              color: "#fff",
            }}
          />
        </div>

        {/* <div className="btn">
          <CustomButton
            onClick={close}
            disable={!values.timesheet.length}
            title="Finish"
            customStyles={{
              width: "100%",
              borderRadius: "5px",
              backgroundColor: "#245293",
              color: "#fff",
            }}
          />
        </div> */}
      </div>
    </div>
  );
};

export default UpdateTimeSheet;
