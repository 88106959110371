import React from "react";
import "./AdminDashboard.css";
import { jobsArray } from "../../components/findJobs/Jobs";
import { BsArrowRight } from "react-icons/bs";
import { BiMoney } from "react-icons/bi";
import { Link } from "react-router-dom";
import { ResizableBox as ReactResizableBox } from "react-resizable";
import ChartGraph from "./chart/ChartGraph";
import JobSummary from "../../components/jobSummary/JobSummary";

const AdminDashboard = () => {
  const statusList = [
    ["#4339F2", "Application"],
    ["#34B53A", "Saved"],
    ["#FFB200", "Views"],
  ];

  return (
    <div className="admin-dashboard-container">
      <div className="admin-dashboard-right">
        <div className="admin-dashboard-right-bottom">
          <div className="admin-info-box">
            <Link className="admin-info-right one" to="/postedjobs">
              <span>My Published Jobs</span>
              <h4>192</h4>
            </Link>

            <div className="admin-info-right two">
              <span>Total Applicants</span>
              <h4>192</h4>
            </div>

            

            <Link className="admin-info-right three">
              <span>Total Payout</span>
              <h4 className="hidden-figure">192</h4>
            </Link>
          </div>

          <div className="graph-container">
            <div className="graph-box">
              <h6>Job Statistics</h6>
              <div className="graph-items">
                {statusList?.map(([color, label], index) => (
                  <div key={index} className="single-graph-item">
                    <div
                      style={{ backgroundColor: color }}
                      className="circle-graph"
                    />
                    <p>{label}</p>
                  </div>
                ))}
              </div>
              <ReactResizableBox
                width={"100%"}
                height={"500"}
                className="chart-bg"
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <ChartGraph />
                </div>
              </ReactResizableBox>
            </div>
            <div className="summary-box">
              <h6>Application Summary</h6>

              <div className="contain-summary">
                <JobSummary />
                <JobSummary />
                <JobSummary />
                <JobSummary />
              </div>

              <div className="cards-total">
                <div className="revenue-total">
                  <BiMoney />
                  <div className="revenue-context">
                    <h4>&#8358; 350.50</h4>
                    <p>Total Earnings Per Annum</p>
                  </div>
                </div>

                <div className="revenue-total">
                  <BiMoney />
                  <div className="revenue-context">
                    <h4>&#8358; 480.00</h4>
                    <p>Total Payout Per Annum</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="admin-openJobs">
            <h4>Open Jobs</h4>

            <div className="admin-openJobs-box">
              {jobsArray.slice(0, 6).map((job, index) => {
                return (
                  <div key={index} className="single-job-box">
                    <div className="single-title-box">
                      <div className="title">
                        <h4>{job.jobTitle}</h4>
                        <span>Rate:N{job.rate}/hr</span>
                      </div>
                      <div className="image">
                        <img src={job.oranizationIMG} alt="img" />
                      </div>
                    </div>
                    <div className="desc">
                      <p>{job.des}</p>
                    </div>
                    <div className="applicants">
                      <h5>15 applicants</h5>
                      <BsArrowRight />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
