import React, { useState } from "react";
import "./ResnReq.css";
import CustomButton from "../../../../../components/customButton/CustomButton";
import { RiDeleteBin6Line } from "react-icons/ri";
import {
  useAddDutyMutation,
  useDeleteDutyMutation,
  useGetDutiesQuery,
  useUpdateDutyMutation,
} from "../../../../../store/query/jobs";
import { toast } from "react-toastify";
import { CiEdit } from "react-icons/ci";
import { useTypedSelector } from "../../../../../hook/useTypedSelector";
import { Bars } from "react-loader-spinner";

const ResnReq = ({ title, close, acc_type, id }) => {
  const [duty, setDuty] = useState({
    id: "",
    data: "",
  });
  const { user } = useTypedSelector((store) => store.appUser);
  const [addDuty, { isLoading: isAddingDuty }] = useAddDutyMutation();
  const [updateDuty, { isLoading: isUpdatingDuty }] = useUpdateDutyMutation();
  const [deleteDuty, { isLoading: isDeletingDuty }] = useDeleteDutyMutation();

  const type = title === "responsibility" ? "responsibilities" : "requirements";
  const {
    data: duties,
    isLoading: isGettingDuties,
    refetch,
  } = useGetDutiesQuery({
    acc_type,
    title,
    job_id: id,
  });
  
  const loading =
    isAddingDuty || isUpdatingDuty || isGettingDuties || isDeletingDuty;

  const handleAdd = async () => {
    if (duty.data.length) {
      const payload = {
        acc_type,
        title,
        body: {
          job_id: id,
          [type]: duty.data,
        },
      };
      const res = await addDuty(payload);
      console.log(res)
      if (res.data.status) {
        toast.success(res.data.message);
        refetch();
        setDuty({ id: "", data: "" });
      } else {
        toast.error("Failed to add!");
      }
    } else {
      toast.error(`Add ${title}`);
    }
  };

  const handleUpdate = async () => {
    if (duty.data.length) {
      const payload = {
        acc_type,
        title,
        id: duty.id,
        body: {
          job_id: id,
          data: duty.data,
          _method: "PATCH",
        },
      };
      const res = await updateDuty(payload);
      console.log(res);
      if (res.data.status) {
        toast.success(res.data.message);
        refetch();
        setDuty({ id: "", data: "" });
      } else {
        toast.error("Failed to update!");
      }
    } else {
      toast.error(`Add ${title}`);
    }
  };

  const handleDel = async (id) => {
    const payload = {
      acc_type,
      title,
      id,
    };

    const res = await deleteDuty(payload);
    console.log(res, payload);
    if (res.data.status) {
      toast.success(res.data.message);
      refetch();
    } else {
      toast.error("Failed to delete!");
    }
  };

  return (
    <div className="dashboard-modal">
      <h3>Update {title}</h3>

      <div className="content">
        <div className="input">
          <textarea
            placeholder={`Type ${title}`}
            rows={2}
            value={duty.data}
            onChange={(e) =>
              setDuty((prev) => ({ ...prev, data: e.target.value }))
            }
          />
          <div className="btn">
            <CustomButton
              onClick={() => {
                if (duty.id) handleUpdate();
                else handleAdd();
              }}
              title={duty.id ? "Update" : "Add"}
              disable={loading}
              loading={loading}
              customStyles={{
                borderRadius: "5px",
              }}
            />
          </div>
        </div>

        <div className="min-box">
          {loading ? (
            <div className="loading-duties">
              <Bars
                height="50"
                width="50"
                color=" #245293"
                ariaLabel="bars-loading"
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : duties?.data && Object.values(duties?.data)[0]?.length === 0 ? (
            <div className="no-res">
              <p>Add Responsibilities</p>
            </div>
          ) : (
            duties?.data &&
            Object.values(duties?.data)[0]?.map(({ id, data }) => (
              <div key={id} className="res-item">
                <div className="res-item-content">
                  <div className="bg-dot">
                    <div className="dot-num" />
                  </div>
                  <div className="res-item-words">
                    <p>{data}</p>
                  </div>
                </div>

                <div className="res-bg-icon">
                  <CiEdit
                    className="icon-btn"
                    onClick={() => {
                      setDuty({
                        id,
                        data,
                      });
                    }}
                    color="#1877F2"
                  />
                  <RiDeleteBin6Line
                    className="icon-btn"
                    onClick={() => handleDel(id)}
                    color="#FF474C"
                  />
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      <div className="btn-bg">
        <div className="btn">
          <CustomButton
            title="Close"
            type="btn"
            onClick={close}
            background={"red"}
            border={"red"}
            customStyles={{
              borderRadius: "5px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ResnReq;
