import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import user, { exitUser } from "./slices/user";
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE } from "redux-persist";
import { configureStore } from "@reduxjs/toolkit";
import { api } from "./BaseConfig";

const persistConfig = {
  key: "root",
  storage,
};

const appUser = persistReducer(persistConfig, user);

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    appUser: appUser,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE],
      },
    }).concat(api.middleware),
});

export const persistor = persistStore(store);

export const persistLogout = () => {
  persistor.purge();
  store.dispatch(exitUser());
};
